<style lang="scss">
    @media (max-width: 575.98px) {
        .testovi {
            .card-body {
                font-size: 0.9rem !important;
                border-bottom: 2px solid red;
                margin-bottom: 15px !important;
                .form-check-label {
                    margin-bottom: 0;
                    font-size: 0.9rem !important;
                    color: black;
                }
                .image-holder {
                    text-align: center;
                }
            }
        }

    }

</style>
<template>
    <div class="testovi">
        <section id="basic-tabs-components">
            <div class="card text-center mb-3" v-if="CurrentQuestion&&!loading">
                <div class="card-body">
                    <h4 class="card-title">{{bodovi}}/{{Test.type=='teory'?'120':'20'}}</h4>
                    <p class="card-text" v-if="Test.type=='teory'&&bodovi<108||Test.type!=='teory'&&bodovi<18">
                        Nazalost Niste Polozili
                    </p>
                    <button type="button" class="btn btn-primary waves-effect waves-float waves-light">Ponovi</button>
                </div>
            </div>
            <div class="row match-height">
                <!-- Basic Tabs starts -->
                <div class="col-xl-12 col-lg-12">
                    <div class="card">
                        <div class="card-body" v-bind:class="{ 'incorrect': CurrentQuestion&&!Question.points, 'correct': CurrentQuestion&&Question.points }" v-for="Question in Questions" :key="Question.id" >
                            <div class="row" v-if="Question.points==0">
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                    <strong class="question-head" v-bind:class="{ 'incorrect': CurrentQuestion&&!Question.points, 'correct': CurrentQuestion&&Question.points }" >{{Question['text']}}</strong>
                                    <div class="form-check form-check"   v-for="QuestionAnswer in Question['answers']" :key="QuestionAnswer.text+'#'+Question.id" v-bind:class="{ 'incorrect': CurrentQuestion&&!QuestionAnswer.tacan, 'correct': CurrentQuestion&&QuestionAnswer.tacan }">
                                        <input class="form-check-input" type="checkbox" v-bind:id="QuestionAnswer.text+'#'+Question.id" v-bind:value="QuestionAnswer.value" v-model="QuestionAnswer.value">
                                        <label class="form-check-label" v-bind:class="{ 'incorrect': CurrentQuestion&&!Question.points, 'correct': CurrentQuestion&&Question.points }" v-bind:for="QuestionAnswer.text+'#'+Question.id">{{QuestionAnswer.text}}</label>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 image-holder">
                                    <img class="image-respons" v-if="Question['image']" v-bind:src="serverRoot+'/storage/app/public/'+Question['url']">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card text-center mb-3">
                        <div class="card-body">
                            <button type="button" class="btn btn-primary waves-effect waves-float waves-light finish-test" v-on:click="Polazi()">Zavrsi</button>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    </div>
</template>
<script>
    import api from '../api'
    export default {
        name: 'TestoviPregled',
        components: {
        },
        data() {
            return {
                Test:null,
                Questions:null,
                CurrentQuestion:1,
                answers:null,
                loading:true,
                bodovi:0,
                serverRoot:process.env.VUE_APP_SERVER_ROOT,
            }
        },
        mounted(){
            let ID=this.$route.params.id;
            let UserId=this.$route.params.uid;
            console.log(ID);
            console.log(UserId);
            this.GetTestoviUserTest().then(data => {
                this.Test=data.data.test;
                this.Questions=data.data.test.questions
                this.answers=data.data.test.answers;
                for(let i=0;i<this.Questions.length;i++)
                {
                    this.Questions[i].answers=JSON.parse(this.Questions[i].answers);
                    this.Questions[i].correctanswers=JSON.parse(this.Questions[i].correctanswers);
                    this.Questions[i].points=this.answers[i].points
                    let tmpAnswers=JSON.parse(this.answers[i].answers);
                    for(let j=0;j<this.Questions[i].answers.length;j++)
                    {
                        let kliknuo=false;
                        let tacan=false;
                        for(let k=0;k<tmpAnswers.length;k++)
                        {
                            if(tmpAnswers[k].value&&this.Questions[i].answers[j]==tmpAnswers[k].text)
                            {
                                kliknuo=true;
                            }
                        }
                        for(let k=0;k<this.Questions[i].correctanswers.length;k++)
                        {
                            if(this.Questions[i].answers[j]==this.Questions[i].correctanswers[k]){ tacan=true;}
                        }
                        let newAnswer={
                            text:this.Questions[i].answers[j],
                            value:kliknuo,
                            tacan:tacan
                        }
                        this.Questions[i].answers[j]=newAnswer;
                    }

                }

                this.loading=false;
            });
        },
        methods:{
            async GetTestoviUserTest() {
                let ID=this.$route.params.id;
                let UserId=this.$route.params.uid;
                return api.GetTestoviUserTest(UserId,ID);
            },
            Polazi:function(){
                this.bodovi=0;
                for(let i=0;i<this.Questions.length;i++)
                {
                    let tacanodgovor=true;
                    for(let j=0;j<this.Questions[i].answers.length;j++)
                    {
                        let tacan=false;
                        for(let k=0;k<this.Questions[i].correctanswers.length;k++)
                        {
                            if(this.Questions[i].answers[j].text==this.Questions[i].correctanswers[k]) tacan=true;
                        }
                        if(tacan && !this.Questions[i].answers[j].value || !tacan&&this.Questions[i].answers[j].value) tacanodgovor=false;
                    }
                    if(tacanodgovor)
                    {
                        this.bodovi+=this.Questions[i].points;

                    }else
                    {
                        this.Questions[i].points=0;
                    }
                    this.CurrentQuestion++
                }
                let ID=this.$route.params.id;
                let UserId=this.$route.params.uid;
                return api.postKorisnikTest(UserId,this.Questions,ID);
            }
        }
    }
</script>
<style lang="scss">
    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
    }
    .image-respons {
        max-width: 20vh;
        max-height: 20vh;
    }
    .card-body {
        font-size: 1.5rem;
        border: 1px solid gray;
        padding: 5px;
        margin: 5px;
    }
    .card-body.correct {
        background: #12e812;
        margin: 20px;

    }
    .card-body.incorrect {
        background: #f44336;
        color: white;
        margin: 20px;
    }
    strong.question-head.incorrect {
        color: white;
    }
    label.form-check-label.incorrect {
        color: white;
    }
    strong.question-head.correct {
        color: white;
    }
    label.form-check-label.correct {
        color: white;
    }

    .form-check-label {
        margin-bottom: 0;
        font-size: 1.3rem;
        color: black;
    }
    strong.question-head {
        color: black;
    }
    input[type="checkbox"]{
        -webkit-appearance: initial;
        appearance: initial;
        background: white;
        border: 1px solid black;
        width: 12px;
        height: 12px;
        margin-right: 5px;
        position: relative;
    }
    input[type="checkbox"]:checked {
        background: white;
    }
    input[type="checkbox"]:checked:after {
        /* Heres your symbol replacement */
        content: "X";
        color: black;
        /* The following positions my tick in the center,
         * but you could just overlay the entire box
         * with a full after element with a background if you want to */
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%,-50%);
        -moz-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        /*
         * If you want to fully change the check appearance, use the following:
         * content: " ";
         * width: 100%;
         * height: 100%;
         * background: blue;
         * top: 0;
         * left: 0;
         */
    }
    button.btn.btn-primary.waves-effect.waves-float.waves-light.finish-test {
        height: 140%;
        width: 45%;
        background: #4caf50 !important;
        font-weight: bold;
        font-size: 1.5rem;
        border: 1px solid #4caf50 !important;
    }
    .form-check.form-check.correct {
        border: 1px solid yellow;
    }
</style>
